// 
// user.scss
// Use this to write your custom SCSS
//

// prevent FOUC
html {
  visibility: visible;
  opacity: 1;
}

// Grid
.row-cols-8 {
  @include row-cols(8);
}

.row-cols-12 {
  @include row-cols(12);
}

@media (min-width: 768px) {
  .row-cols-md-8 {
    @include row-cols(8);
  }

  .row-cols-md-12 {
    @include row-cols(12);
  }  
}

// Override .bg-secondary
.bg-secondary {
  background-color: $secondary !important;
}

// Typography

:root {
  //font-size: 16px;

  @media (max-width: 767px) {
    font-size: 15px;
  }
}

h1, .h1 {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.050em;
  line-height: calc(55 / 50);

  &:not(:last-child) {
    margin-bottom: 2.5rem;

    @media (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
  }
}

h2, .h2 {
  font-weight: 700;
  letter-spacing: 0;
  line-height: calc(40 / 33);

  h1 + &, .h1 + & {
    margin-top: -1.4rem;
  }

  &:not(:last-child) {
    margin-bottom: calc(50em / 33);

    @media (max-width: 767px) {
      margin-bottom: calc(25em / 33);
    }
  }
}

.spacing-wide {
  letter-spacing: 0.100em;
}

.colored {
  color: var(--spot-color);

  &.btn {
    background-color: var(--spot-color) !important;
    color: var(--spot-contrast) !important;
  }
}

.font-copy {
  font-family: $font-family-paragraph;
  font-weight: 300;
  font-size: 1.2rem;  
  line-height: 1.6;
}

// Links
a {
    text-decoration: none;

    &:hover {
        text-decoration-color: $body-color;
    }
}

p a, label a {
  text-decoration: none;
  border-bottom: $body-color 1px dotted;

  &:hover {
      text-decoration: none;
      border-bottom: $body-color 1px solid;
  }
}

// Image Group Block

.imageGroupSmall {

  img{
    height: 287px;
    width: auto;
    object-fit: contain;
  }

}

.imageGroupMedium {
  
  img {
    height: 431px;
    width: auto;
    object-fit: contain;    
  }

}

.imageGroupLarge {
  
  img {
    height: 575px;
    width: auto;
    object-fit: contain;    
  }

}

// Buttons
.btn {
  white-space: normal;
  
  // fix baseline shift
  &::before {    
    content: "";
    vertical-align: 0.5ex;
  }
}
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    border-color: rgba($value, 1);
  }
}
.btn-primary, .btn-outline-primary {
  text-transform: uppercase;
  letter-spacing: 0.100em;
  font-size: 1.25rem;
}

.btn-light:hover,
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show>.btn-light.dropdown-toggle {
 //color: theme-color('light') !important;
 background-color: #999;
}

// Forms

input, select, optgroup, textarea {
  font-family: $font-family-paragraph;
  font-weight: 300;
}

.hlSeparator {
  font-size: 0;

  &::after {
    content: "";
    display: inline-block;
    width: 60px;
    height: 3px;
    background-color: #000;
  }
}

.form-group.required {
  label:after {
    content:"*";
    margin-left: 0.2rem;
  }
}

////////

// NAVs
//

.navbar {
  .container {
    padding: 0 0 10px;
    border-bottom: 1px solid #CBCBCB;
    // margin-bottom: 18px;
  }
}
.navbar-brand {
  letter-spacing: 0.100em;
}

.breadcrumb-container {
  padding-right: 0;
  padding-left: 0;
}

.breadcrumb-item {
  font-size: 1.25rem;

  & > a {
    color: #000;
    text-decoration-color: transparent; 
    transition: text-decoration-color 0.5s;

    &:hover {
      text-decoration: underline;
      text-decoration-color: #000;
    }
  }

  &::before {
    font-size: 0.5em;
    vertical-align: 0.5ex;
  }
  &+.breadcrumb-item {
    padding-left: 1rem;

    &::before {
      padding-right: 1rem;
    }
  }
}

.nav-item {
  &.active {
    .nav-link {
     font-weight: $font-weight-bold;
    }
  }
}

.nav-link {
  //color: #666;
  text-transform: uppercase;
  letter-spacing: 0.100em;
  text-decoration: underline;
  text-decoration-color: transparent; 
  transition: text-decoration-color 0.5s;

  &:hover {
    color: #000;
    text-decoration: underline;
    text-decoration-color: #000;
  }
}

.breadcrumb-item {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.100em;
  text-decoration: underline;
  text-decoration-color: transparent;

  &:hover {
    color: #000;
    text-decoration: underline;
    text-decoration-color: #666;
  }
}

.breadcrumb-item.active {
  color: #000;
  font-weight: 600;

  &:hover {
    text-decoration: none;
  }
}

// Images
.figure {
  border:none;
  padding:0;
}

// Elements
.tagList__tag {
  text-decoration: none;
  border-bottom: rgba(0,0,0,.2) 1px solid;
  transition: border-color 0.3s;

  &:hover {
      text-decoration: none;
      border-bottom: rgba(0,0,0,1) 1px solid;
  }
}


// Shop
.cz-thumblist-item {
  height: auto !important;
  
  & > img {
    opacity: unset;

    &:hover{
      border: 1px solid #CBCBCB;
      padding: 4px;
    }    

  }

  &.active > img {
    border: 1px solid #CBCBCB;
    padding: 4px;
  }     

}

.cz-preview-item {
    display:none;
}

.cz-preview-item.active {
    display:block;
}

.cz-sidebar-static {
  @include media-breakpoint-up(lg) {  
    max-width: 100%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

// Separator
hr, .hr {
  border-color: #CBCBCB;
}

// readMore
.readMore {
  max-height: 3.75rem;
  overflow: hidden;
  transition: max-height 0.3s;

  &--active {
    max-height: 999em;
  }
}

.readMore__btn {
  display: inline-block;
  //margin-right: auto;
  margin-bottom: 1rem;
  //margin-left: auto;

  @at-root .readMore--active > & {
    display: none;
  }
}

// Loader

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 3rem;
  width: 3rem;
  animation: rotate 0.8s infinite linear;
  border: 2px solid $loader-fore-color;
  border-right-color: transparent;
  border-radius: 50%;
  z-index: 9999;
}

@keyframes rotate {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

#block {
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  background: $loader-background-color;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

// GALLERY
.cz-carousel.cz-controls-light [data-controls] {
  font-size: 1.8rem;
  background-color: transparent;
  color: #fff;
  border: none;
  text-shadow: 1px 1px 5px rgba(0,0,0,0.5);
}
.cz-carousel [data-controls='prev'] {
  left: 0.5rem;
}
.cz-carousel [data-controls='next'] {
  right: 0.5rem;
}

// LIGHTBOX
.lg-outer .lg-item {
  background: url(/assets/images/loading.gif) center center no-repeat;
}

.lg-backdrop.in {
  opacity: .7;
}

.lg-toolbar {
  background: transparent;
}

.lg-toolbar .lg-icon, #lg-counter {
  color:white;
}

// ACCORDION
.accordion {
  margin-top: 3rem;
}

.accordion::before {
  content: "";
  position: absolute;
  width: 130px;
  height: 130px;
  z-index: -1;
  background: url(https://com-hernuss.s3.eu-central-1.amazonaws.com/Service/Cookie-Emu-Richtlinien-HPFH-KJU-cut-small.png) 0 0 no-repeat;
  top: -60px;
  left: calc(50% - 68px);
  transform: rotate(3deg);
  background-size: 100%;

}

// EMU Deco

.emu-deco::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: -1;
  background: url(https://com-hernuss.s3.eu-central-1.amazonaws.com/Service/Cookie-Emu-Richtlinien-HPFH-KJU-cut-small.png) 0 0 no-repeat;
  top: -60px;
  left: calc(50% - 50px);
  transform: rotate(3deg);
  background-size: 100%;
  animation: fadein 1s linear forwards;
  animation-delay: 2s;  
  opacity: 0;

}

@keyframes fadein {
  0% { opacity: 0 }
  100% { opacity: 1 }
}



// Cookiebot

#CybotCookiebotDialog {
  border:0 !important;
  border-radius: 0 !important;
  transform: none !important;
}

#CybotCookiebotDialogBodyContentTitle {
  text-align: center !important;
  font-family: $font-family-sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: 0.050em !important;  
  text-transform: uppercase !important;
  font-size: 2rem !important;
  margin-top: 1rem !important;  
}

#CybotCookiebotDialogBodyContentText {
  font-family: $font-family-sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

.CybotCookiebotDialogBodyButton {
  text-transform: uppercase !important;
  letter-spacing: 0.100em !important;
  font-size: 1rem !important;    
  font-family: $font-family-sans-serif !important;
  padding: 10px 10px 5px 10px !important;
  background-color: #F3F3F3 !important;
  color:#000 !important;
  border:0 !important;
}

#CybotCookiebotDialogBodyButtonDecline {
  width: 230px !important;
}

#CybotCookiebotDialogBodyButtonAccept {
  //background-color:#E173BA !important;
  //border:0 !important;
  width: 250px !important;
}

#CybotCookiebotDialogDetailBodyContent {
  border-bottom-width:1px !important;
}

#CookieDeclarationChangeConsent {
  a {
    text-decoration: none !important;
    border-bottom: #000 1px dotted !important;
  }
}

#CybotCookiebotDialogBodyContentText {
  a {
    text-decoration: none !important;
  }
}

.CookieDeclaration {
  .CookieDeclarationIntro {
    display:none;
  }

  p{
    font-family: $font-family-paragraph;
    font-weight: 300;
    font-size: 1.2rem;  
    line-height: 1.6;
  }
}


#CybotCookiebotDialog::before {
  content: "";
  position: absolute;
  width: 470px;
  height: 400px;
  top: 42px;
  left: 86%;
  z-index: -1;
  background: url(https://com-hernuss.s3.eu-central-1.amazonaws.com/Service/Cookie-Emu-Richtlinien-HPFH-KJU-cut-small.png) 0 0 no-repeat;
  transform: rotate(-42deg);
  background-size: 33%;
}

@media (max-width: 560px) {

  #CybotCookiebotDialog::before {
    content: "";
    top: 46px;
    left: 88%;
    z-index: -1;
    background-size: 30%;
  }

  #CybotCookiebotDialogBodyContentText {
    font-size: 0.8rem !important;
    line-height: 1.3 !important;
    margin-top: 0.5rem;
    margin-bottom: 0;    
  }

  #CybotCookiebotDialogBodyContentTitle {
    margin-top: 0 !important;  
  }

}

.pepsi-overview {

    ul {
      padding-left: 2rem;
    }
}

.pepsi-index__tile {

  flex-basis: 10%;
  padding-right: 15px;
  padding-left: 15px;

  img {
    &:hover {
      border: 1px solid #CBCBCB;
      padding: 4px;
    }
  }

  @media (max-width: 992px) {
    flex-basis: 16.67%;    
    padding-right: 5px;
    padding-left: 5px;

  }

}

.pepsi-index_tile-title {
  @media (max-width: 767px) {
      font-size: 0.9rem !important;
  }
}

.pepsi-product {
  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;

    @media (max-width: 767px) {
      font-size: 1.8rem;
    }
  }

  select {
    width: 25%;

    @media (max-width: 767px) {
      width: 30%;
    }    

    option.soldout {
      color:#ccc;
    }
  }  

  .cz-thumblist {
    flex-wrap: wrap;
  }
  
  .cz-thumblist-item {
    @media (min-width: 767px) {
        width: 5.5rem
    }
  }

  .cz-preview-item {
    &:hover { cursor: unset; }
  }  
  

}

.pepsi-product__description {

  @media (max-width: 767px) {
    font-size: 1rem;

    ul {
      padding-left: 1rem;
    }
  }

  ul {
    ul {
      margin-bottom: 1rem;

      li:first-child {
        padding-top: 0.5rem;
      }
    }
  }



}


