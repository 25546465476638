// 
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Example of a variable override to change Cartzilla background color
// Remove the "//" to comment it in and see it in action!
$enable-prefers-reduced-motion-media-query:   false;
$enable-responsive-font-sizes:                true !default;

// Fonts
$path-to-fonts:               '';
$fontpath: '../fonts' !default;
@font-face {
    font-family: 'Space-Grotesk';
    src: url('#{$fontpath}/SpaceGrotesk-Bold.woff2') format('woff2'), url('#{$fontpath}/SpaceGrotesk-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Space-Grotesk';
    src: url('#{$fontpath}/SpaceGrotesk-Light.woff2') format('woff2'), url('#{$fontpath}/SpaceGrotesk-Light.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('#{$fontpath}/roboto-v30-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fontpath}/roboto-v30-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local(''),
       url('#{$fontpath}/roboto-v30-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fontpath}/roboto-v30-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('#{$fontpath}/roboto-v30-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fontpath}/roboto-v30-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('#{$fontpath}/roboto-v30-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fontpath}/roboto-v30-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('#{$fontpath}/roboto-v30-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fontpath}/roboto-v30-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local(''),
       url('#{$fontpath}/roboto-v30-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fontpath}/roboto-v30-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('#{$fontpath}/roboto-v30-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fontpath}/roboto-v30-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('#{$fontpath}/roboto-v30-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fontpath}/roboto-v30-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$font-family-sans-serif:      'Space-Grotesk', sans-serif !default;
$font-size-base: (20rem / 16);

$font-family-paragraph: 'Roboto', sans-serif;

$h1-font-size:                $font-size-base * 2.8 !default;
$h2-font-size:                $font-size-base * 1.65 !default;

// Brand colors
$primary:               #000;
$secondary:             #F3F3F3;
$headings-color:        #000;
$box-shadow:            10px 10px 20px #00000033;
$border-color:          #CBCBCB; //#F3F3F3;

$light:         #F3F3F3;
$dark:          #000;

:root {
  --spot-color: $light;
  --spot-contrast: $dark;
}

// GRID
$container-max-widths: () !default;
$container-max-widths: map-merge(
  (
    xl: 1668px
  ),
  $container-max-widths
);

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    6: ($spacer * 4.5),
    7: ($spacer * 6),
    8: ($spacer * 7.5),
    9: ($spacer * 9),
    10: ($spacer * 10)
  ),
  $spacers
);

// Body
$body-color:            #000;


// Lists
$list-style-color:      #000;


// Links
$link-color:            #000;
$link-decoration:       underline;
$link-hover-decoration: underline;
$link-transition:       color .25s ease-in-out, text-decoration-color .25s ease-in-out;

$border-width:          1px; // 3px;

// Buttons
$input-btn-padding-y:               .8rem;
$btn-padding-x:                     2rem;

$btn-light-color:                   #000;
$btn-font-weight:                   600;

// Navs
$nav-link-padding-y:                      .25rem;
$nav-link-padding-x:                      .5rem;

$nav-link-dark-color:                     #666;
$nav-link-dark-hover-color:               #000;
$nav-link-transition:                     color .25s ease-in-out, background-color .25s ease-in-out, box-shadow .25s ease-in-out, border-color .25s ease-in-out, text-decoration-color .25s ease-in-out;

$breadcrumb-light-active-color:           #000;
$breadcrumb-light-divider-color:          #000;
$breadcrumb-divider-color:                #000;

// Product gallery
$product-gallery-thumb-border-width:        0;
$product-gallery-thumb-border-radius:       0;

// Forms 
$input-focus-border-color:          #000;
$custom-option-border-radius:       0;
$input-border-radius:               0;
$input-border-color:                #ccc;
$custom-select-border-radius:       0;
$custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-border-color:#ccc;  

//alerts
$alert-border-radius:               0;

//loader
$loader-fore-color:#000;
$loader-background-color: rgba(255,255,255,0.8);